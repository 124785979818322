import { useContext, useEffect, useState } from "react";
import { AuthContext } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';

export default function AuthorizedRoute({ children }) {
    const { authed, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true); // Track loading state
    const storedAuth = JSON.parse(localStorage.getItem('_id'));
    const storedToken = storedAuth?.accessToken;

    useEffect(() => {
        const checkAuthorization = async () => {
            // Check if the stored token is valid
            if (authed === storedToken && storedToken && new Date(jwt.decode(storedToken)?.expiration) - Date.now() >= 0) {
                setLoading(false); // Token is valid, stop loading
            } else {
                // Logout and then redirect
                await logout();
                setLoading(false);
            }
        };
        checkAuthorization();
    }, [authed, storedToken, logout]);
    if (loading) {
        return null; // Render nothing while checking the token
    }
    // After logout or invalid token, redirect to login
    if (!authed || !storedToken || new Date(jwt.decode(storedToken)?.expiration) - Date.now() < 0) {
        return <Navigate to="/login" />;
    }
    return children;
}
